.portfolio_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.portfolio_item {
    background: var(--color-bg-variant);
    /* padding: 1.2rem; */
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: max-content;
    text-align: center;
}

.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item h2 {
    margin: 1.2rem;
    font-size: 2rem;
}

.portfolio_item-container {
    display: grid;
    grid-template-columns: repeat(1fr);
    margin: 1.2rem;
    justify-items: center;
    align-items: center;
}

.portfolio_item-content {
    text-align: left;
    padding: 2rem;
    margin: 1.2rem;
}

.portfolio_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 300px;
}

.portfolio_item-cta {
    display: flex;
    gap: 2rem;
    margin: 3rem;
    justify-content: center;
}

.btn-read_more {
    margin: 3rem;
}

.portfolio_item_icon {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin: 1rem;
}

/* --------------------- MEDIA QUERIES (MEDIUM DEVICES) -------------------- */
@media screen and (max-width: 1024px){
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* --------------------- MEDIA QUERIES (SMALL DEVICES) -------------------- */
@media screen and (max-width: 600px){
    .portfolio_container {
         grid-template-columns: 1fr;
         gap: 1rem;
    }
}